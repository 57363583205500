import * as i18n	from "@geotoura/shared/i18n";

import { cookieWarning }	from "@geotoura/common/privacy/cookieWarning";

import { territorybox }		from "@geotoura/wp-theme/inc/territorybox";
import { testimonial }		from "@geotoura/wp-theme/inc/testimonial";
import { newsletterbox }	from "@geotoura/wp-theme/inc/newsletterbox";
import { menuButton }		from "@geotoura/wp-theme/inc/menuButton";
import { topButton }		from "@geotoura/wp-theme/inc/topButton";
import { scrollbarWidth }	from "@geotoura/wp-theme/inc/scrollbarWidth";

const init	= ():void	=> {
	const languageCode:i18n.LanguageCode =
		i18n.LanguageCode.valueOrDefault(document.documentElement.getAttribute("lang"));

	cookieWarning(languageCode);

	scrollbarWidth();

	// to set hover-styles only on no-touch devices - to prevent iphone troubles
	if ("ontouchstart" in window || "msMaxTouchPoints" in navigator) {
		document.body.classList.add("touch");
	}
	else {
		document.body.classList.add("no-touch");
	}

	const route		= document.body.getAttribute("data-route")		?? "";
	const region	= document.body.getAttribute("data-region")		?? "";
	const territory	= document.body.getAttribute("data-territory")	?? "";
	const upLinker	= document.querySelector<HTMLLinkElement>(".js-uplinker");

	const params =
		territory !== ""	? `?territory=${territory}`		:
		region !== ""		? `?region=${region}`			:
		route !== ""		? `?exampleRouteId=${route}`	:
		"";

	if (upLinker !== null) {
		upLinker.href = `/up/index.html${params}`;
	}

	territorybox();
	testimonial();
	newsletterbox();
	menuButton();
	topButton();

	const heroVideo = document.querySelector<HTMLVideoElement>(".hero-video");
	if (heroVideo !== null) heroVideo.playbackRate = 0.5;
};

window.addEventListener("DOMContentLoaded", init);
